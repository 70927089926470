.user-search-results > h2 {
    margin: 0;
    padding: 10px;
    background: #252525;
    font-size: 24px;
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    font-weight: normal;
}

.user-search-results > ul {
    padding: 20px;
    list-style: none;
    background: #f3f3f3;
}

.user-search-results > ul .loader {
    display: none;
    color: #777;
    font-size: 20px;
    text-align: center;
}

.user-search-results > ul .loader > span {
    display: block;
}

.user-search-results > ul .loader > i {
    margin-top: 10px;
    font-size: 30px;
}

.user-search-results.is-fetching > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-search-results.is-fetching > ul .loader {
    display: block;
}

.user-search-results > ul > li {
    padding: 10px 5px;
    margin: 0 auto;
    background: #F3F3F3;
    max-width: 1000px;
    border-bottom: 1px solid #DDD;
    font-size: 18px;
    cursor: pointer;
}

.user-search-results > ul > li:hover {
    background: #EEE;
}

.user-search-results .user-icon {
    width: 3%;
    color: #999;
    font-size: 20px;
}

.user-search-results .user-name {
    display: inline-block;
    width: 25%;
}

.user-search-results .user-emails {
    display: inline-block;
    width: 72%;
}

.user-search-form > h2 {
    font-size: 30px;
    font-weight: normal;
    color: #777;
    text-align: center;
}

.user-search-form > form {
    max-width: 800px;
    margin: 20px auto;
}
