.user-info > header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 2% 10px 0;
    background: #252525;
    color: #FFF;
}

.user-info > header > a {
    padding: 0 20px;
    color: #CCC;
    font-size: 12px;
}

.user-info > header > a > i {
    padding-right: 5px;
}

.user-info > header > a:hover {
    color: #FFF;
}

.user-info > header > h1 {
    text-align: centerm;
}

.user-info-actions {
    background: #F3F3F3;
}

.user-info-actions .show-emails-button:after {
    content: "Show Emails";
}

.is-showing-email-list .user-info-actions .show-emails-button:after {
    content: "Hide Emails";
}

.user-info-actions-inner {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.user-info-actions-inner > form,
.user-info-actions-inner > .show-emails-button {
    margin: 0 0 0 40px;
}

.user-info-actions .form-group {
    display: flex;
    margin: 0;
}

.user-info-actions .form-group button {
    margin-left: 10px;
}

.user-email-list {
    display: none;
}

.user-email-list.is-visible {
    display: block;
}
