.create-subs-order-form > h2 {
  font-size: 30px;
  font-weight: normal;
  color: #777;
  text-align: center;
}

.create-subs-order-form > form {
  max-width: 800px;
  margin: 20px auto;
}
