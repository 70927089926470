html {
  font-size: 14px !important;
}

.WPE__wrapper {
  --blue: #004966;
  --darkBlue: #002838;
  --blueWhite: #f0fbff;
}

.WPE__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(0deg, var(--blue), var(--darkBlue));
  padding: 40px 0 0;
  margin: 0;
  height: 100vh;
}

.WPE__site__data table {
  min-width: auto;
}

.WPE__site__data th,
.WPE__site__data td {
  font-size: 14px;
}

.WPE__actions {
  padding: 20px 0;
}

.WPE__actions__column {
  display: inline-flex;
  flex-direction: column;
}

.WPE__actions button {
  margin-bottom: 10px;
}

.WPE__actions .MuiFormControl-root {
  margin-bottom: 10px;
}

.WPE__actions__searchReplace button + button {
  margin-left: 10px;
}

.WPE__log {
  width: 100%;
  background: var(--blueWhite);
  border-top: 1px solid var(--darkBlue);
  padding: 1%;
  flex: 1;
  overflow: hidden;
}

.WPE__log__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.WPE__log header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.WPE__log__title {
  padding: 0;
  margin: 0;
  font-size: 24px;
}

.WPE__log__title span {
  font-size: 16px;
}

.WPE__log__throbber {
  margin-right: 10px;
}

.WPE__log__messages {
  flex: 1;
  overflow-y: auto;
}

.WPE__log__message {
  border-left: 5px solid green;
  background: #00000008;
}

.WPE__log__message--error {
  border-left: 5px solid red;
}

.WPE__log__message + .WPE__log__message {
  margin-top: 10px;
}

.WPE__log__message code {
  padding: 2px 4px;
  font-size: 90%;
  color: #888;
  background-color: #eee;
  border-radius: 4px;
  margin-bottom: 20px;
}

.WPE__log__message p {
  padding: 2px 8px;
  margin: 0;
}
