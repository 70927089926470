.line-item-list {
    max-width: 1000px;
    margin: 0 auto;
}

.line-item-list__line-item {
  padding: 30px 20px;
  border-top: 1px solid #DDD;
}

.line-item-list__line-item .line-item-data {
    display: flex;
}

.line-item-list__line-item ul {
    padding: 0;
    list-style: none;
}

.line-item-list__line-item .line-item-data-primary {
    font-size: 16px;
    margin-right: 20px;
}

.line-item-list__line-item .line-item-data-secondary {
    font-size: 13px;
    opacity: 0.5;
}

.line-item-list__line-item:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.line-item-list__line-item > ul {
  list-style: none;
  padding: 20px 10px;
}

.line-item-list__line-item--failed {
  background-color: #e9c9c9 !important;
}

.line-item-list__line-item .registrations {
  padding-left: 0.5em;
}

.line-item-list__line-item__upgrade-user {
  font-size: 16px;
  margin: 10px 0 0 0;
  padding: 5px !important;
}

.line-item-list__line-item--blacklisted {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
