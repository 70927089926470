.refund-form {
    margin-top: 10px;
    padding-top: 10px;
}

.refund-form .refund-form-inner {
    display: flex;
    align-items: center;
}

.refund-form h3 {
    font-size: 16px;
    margin: 0;
}

.refund-form .form-control {
  margin:0 10px;
}

.refund-form .form-group {
  margin: 0 5px;
  display: block;
}

.refund-form label {
  margin-right: 10px;
}

.refund-form button {
  display: block;
  margin: 0;
}

.refund-form .refund-note {
  display: block;
  margin: 8px;
}

.refund-form .refund-note label {
  display: block;
}
