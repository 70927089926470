@import 'WPE-common.css';

.WPE__site {
  width: 100%;
  max-width: 800px;
  background: #fff;
  padding: 2%;
  flex: 0 1 60vh;
  overflow-y: auto;
}
