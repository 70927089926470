@import 'WPE-common.css';

.WPEDashboard {
  padding: 5% 3%;
  max-width: 600px;
}

.WPEDashboard a {
  cursor: pointer;
}

.WPEDashboard > div + div {
  margin-top: 40px;
}

.WPEDashboard section {
  border: 1px solid #ddd;
  padding: 20px 10px;
  background: #fbfbfb;
}

.WPEDashboard__BySite {
  max-height: 600px;
  overflow: auto;
}

.WPEDashboard__BySite__account em {
  color: #999;
}

.WPEDashboard__Log {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  max-width: 800px;
  min-height: 400px;
  max-height: 95vh;
  padding: 20px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 30%);
  z-index: 2;
  transform: translate(-50%, -50%);
}

.WPEDashboard__Log header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.WpeDashboard__Log__Title--error {
  color: red;
}

.WpeDashboard__Log__Title--success {
  color: blue;
}

.WpeDashboard__Log__Title--error:before,
.WpeDashboard__Log__Title--error:after {
  content: "!";
  padding-right: 10px;
  padding-left: 10px;
}

.WPEDashboard__Log__Overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
}

.WPEDashboard__Log__Throbber {
  font-size: 20px;
  margin-right: 10px;
}

.WPEDashboard__Log__Buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
}

.WPEDashboard__search {
  position: relative;
  margin-bottom: 10px;
}

.WPEDashboard__search .fa {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.WPEDashboard__search input {
  border: none;
  background: #f3f3f3;
  font-size: 16px;
  line-height: 100%;
  padding: 4px 20px 4px 24px;
}

.WPEDashboard__BySite__site {
  margin-bottom: 20px;
  padding: 4px 8px;
}

.WPEDashboard__BySite__site__header {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.WPEDashboard__BySite__site__header__links {
  display: flex;
  align-items: center;
}

.WPEDashboard__BySite__site__header__links a {
  display: inline-flex;
  align-items: center;
  line-height: 1em;
}

.WPEDashboard__BySite__site__header__links a + a {
  margin-left: 10px;
}

.WPEDashboard__BySite__site__header__links svg {
  font-size: 14px;
}
